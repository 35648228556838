import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("utilityStore", ["uiSwitchSettingDatas"]),
    },
    data() {
        return {
            imagePreview: null,
            imageFile: null,
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
            sleepTime: 500, // 兩秒鐘等待時間
        };
    },
    methods: {
        /**
         * 等待時間
         * @param { type Number(數字) } time 等待時間
         * @param {  tpye Number(數字) } i 陣列索引
         * @returns
         */
        async sleep(time, i) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    console.log("起床");
                    resolve();
                }, i * time);
            });
        },
        //上傳圖片超過限制數量
        imageOverLimit() {
            this.$message({
                type: "error",
                message: "超過上傳圖片上限",
            });
        },
        /**
         * 圖片變更時
         * @param { type File(檔案) } file 上傳檔案資料
         * @param { type Array(陣列) } fileList 檔案列表資料
         * @returns
         */
        handleFileChange(file) {
            if (
                file.size >
                (this.uiSwitchSettingDatas.upload_photo_size ??
                    Number(process.env.VUE_APP_UPLOAD_PHOTO_SIZE)) *
                    1024 *
                    1024
            ) {
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: `圖片尺寸不能大於 ${
                        this.uiSwitchSettingDatas.upload_photo_size ??
                        process.env.VUE_APP_UPLOAD_PHOTO_SIZE
                    }MB`,
                });
                return;
            }
            // 判斷是否有選擇對象
            if (this.users.length <= 0) {
                return;
            }
            // 清空上傳列表 不然會超過上傳檔案數量限制
            this.fileList = [];
            // 將上傳檔案存入此變數
            this.imageFile = file;
            // 新增一個讀取上傳檔案方法
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                this.imagePreview = reader.result;
            };
        },
        /**
         * 群發上傳圖片事件
         * @param { type Array(陣列) } users 群發聊天對象
         */
        async isUploadImage(users) {
            this.sendLoading = true;
            const data = await this.uploadAttachments(
                this.imageFile,
                process.env.VUE_APP_SERVICE_CHAT_ID
            );
            // 判斷上傳圖片失敗時 不往下執行
            if (!data.success) {
                return;
            }
            // 發送至firebase 資料
            const sendData = {
                message: data.imgUrl,
                userIds: users,
                type: "image",
                ownerId: this.user.id,
            };
            try {
                await this.$api.ChatSendNotifyMessagesApi(sendData);
                this.sendLoading = false;
                this.clearnMessage();
                this.$message({
                    type: "success",
                    message: "群發圖片成功",
                });
                return true;
            } catch (err) {
                this.sendLoading = false;
                // 判斷是否有失敗資料
                if (err.response.data.error.errorDatas) {
                    this.errorDatas = err.response.data.error.errorDatas;
                }
                this.$message({
                    type: "error",
                    message: `群發圖片失敗`,
                });
                return false;
            }
        },
        /**
         * 檔案上傳
         * @param { type File(檔案) } file 檔案資料
         */
        async uploadAttachments(file) {
            try {
                const { data } = await this.$api.ChatUploadAttachmentsApi(file);

                // 取消讀取歷史訊息事件
                this.$emit("update:isReadHistory", false);
                // 清空檔案列表
                this.fileList = [];
                return { success: true, imgUrl: data.url };
            } catch (err) {
                // 清空檔案列表
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: "上傳檔案失敗",
                });
                return { success: false };
            }
        },
    },
};
