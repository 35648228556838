import { mapState, mapActions } from "vuex";
export default {
    computed: {
        ...mapState("userStore", ["user"]),
    },
    data() {
        return {
            message: "",
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
            sendLoading: false,
            errorDatas: [],
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 等待時間
         * @param { type Number(數字) } time 等待時間
         * @param {  tpye Number(數字) } i 陣列索引
         * @returns
         */
        async sleep(time, i) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    console.log("起床");
                    resolve();
                }, i * time);
            });
        },
        /**
         * 發送訊息
         * @param { type Array(陣列) } users 要發送訊息的對象
         */
        async isSendMessage(users) {
            this.errorDatas = [];
            // 判斷沒有輸入訊息時 不能做送出
            if (this.message.length < 1) {
                return;
            }
            this.sendLoading = true;
            const sendData = {
                message: this.message,
                userIds: users,
                ownerId: this.user.id,
                type: "message",
            };
            try {
                await this.$api.ChatSendNotifyMessagesApi(sendData);
                this.sendLoading = false;
                this.clearnMessage();
                this.$message({
                    type: "success",
                    message: "群發成功",
                });
                return true;
            } catch (err) {
                this.sendLoading = false;
                // 判斷是否有失敗資料
                if (err.response.data.error.errorDatas) {
                    this.errorDatas = err.response.data.error.errorDatas;
                }
                this.$message({
                    type: "error",
                    message: `群發失敗`,
                });
                return false;
            }
        },
        /**
         * 清空輸入匡訊息內容
         */
        clearnMessage() {
            this.message = "";
        },
    },
    created() {},
};
