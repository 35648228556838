<template>
    <div>
        <SearchForm ref="searchForm" @onSearch="filterSearch" @onReset="resetSearchData" />
        <div
            v-loading="loading"
            element-loading-text="載入中"
            element-loading-spinner="el-icon-loading text-red-600"
            element-loading-customClass="flex justify-center items-center"
            class="bg-white px-3 py-3 w-full rounded-lg relative mt-5">
            <!-- <LoadingComponent :isLoading="loading" customClass="absolute w-full" /> -->
            <!-- <ul class="bg-gray-200 my-2">
                <li v-for="user in users" :key="user" class="pb-3 flex">
                    {{ user }}
                </li>
            </ul> -->
            <textarea
                v-if="imagePreview === null"
                v-model="message"
                class="text-sm pl-3 pt-1 rounded-lg border border-gray-100 mr-2 min-h-[30px] w-full"
                rows="10"></textarea>
            <img v-if="imagePreview !== null" class="w-full rounded-lg max-w-[600px]" :src="imagePreview" alt="" />
            <div v-if="users.length > 0" class="flex">
                <h5 class="p-2">發送名單_總計數量: {{ users.length }}</h5>
                <div class="flex flex-1 justify-end">
                    <!-- 上傳圖片功能 -->
                    <el-upload
                        v-if="message.length <= 0"
                        id="chatUpload"
                        ref="upload"
                        list-type="picture-card"
                        :on-change="handleFileChange"
                        :auto-upload="false"
                        :file-list="fileList"
                        :limit="1"
                        action=""
                        :on-exceed="imageOverLimit"
                        class="flex items-center flex-grow-0"
                        accept=".jpg,.jpeg,.png,.JPG,.JPEG">
                        <img class="cursor-pointer mr-2 h-full" src="img/icons/web/chats/photo-gray.svg" alt="" />
                    </el-upload>
                    <button
                        v-permission="['create']"
                        v-loading="sendLoading"
                        class="px-3 bg-blue-500 rounded-lg py-1 text-white hover:bg-blue-700 duration-500"
                        @click.prevent="send(users)">
                        群發
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 發送訊息方法
import SendMessageMixin from "../service/sendMessageMixin";
// 上傳圖片方法
import UploadMixin from "../service/uploadMixin";
// 搜尋表單
import SearchForm from "./SearchForm.vue";
export default {
    mixins: [SendMessageMixin, UploadMixin],
    components: {
        SearchForm,
    },
    data() {
        return {
            // 發送名單
            users: [],
            // 搜尋條件
            filterData: {},
            fileList: [],

            loading: false,
        };
    },
    methods: {
        /**
         * @param { tpye Object(物件) } filterData 搜尋過濾資料
         */
        filterSearch(filterData) {
            this.errorDatas = [];
            this.filterData = {};
            this.filterData = { ...filterData, page: 1, limit: 1000000 };
            // 判斷沒有傳送 指定身份值時 預設給予 roles = [1,2] 此兩種身份代表服務商
            if (this.filterData.roles === undefined) {
                this.filterData["roles"] = [1, 2];
            }
            this.getLists({
                ...this.filterData,
            });
        },
        async getLists(filterData) {
            this.loading = true;
            try {
                const { data } = await this.$api.GetProviderListApi(filterData);
                const users = data.data.map((item) => ({ banana_id: item.banana_id, id: item.id }));
                this.users = users;
                this.loading = false;
            } catch (err) {
                this.loading = false;
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得服務商資料失敗",
                });
            }
        },
        // 群發
        async send(users) {
            if (this.imagePreview !== null) {
                const result = await this.isUploadImage(users);
                if (result) {
                    this.reset();
                    this.getLists(this.filterData);
                }
            } else {
                const result = await this.isSendMessage(users);
                if (result) {
                    this.reset();
                    this.getLists(this.filterData);
                }
            }
        },
        // 重置搜尋資料
        resetSearchData() {
            // 預設搜尋資料
            this.defaultParams();
            this.fileList = [];
            this.imagePreview = null;
            this.imageFile = null;
            this.users = [];
            this.clearnMessage();
        },
        // 重置發送名單
        reset() {
            this.getLists(this.filterData);
            this.fileList = [];
            this.imagePreview = null;
            this.imageFile = null;
            this.clearnMessage();
        },
        // 預設搜尋資料
        defaultParams() {
            this.filterData = {
                roles: [1, 2],
                page: 1,
                limit: 1000000,
            };
        },
    },
    async created() {
        this.defaultParams();
        // await this.getLists(this.filterData);
    },
};
</script>

<style lang="scss" scoped>
::v-deep #chatUpload {
    .el-upload-list {
        @apply hidden p-0 m-0;
    }
}
::v-deep #chatUpload {
    .el-upload--picture-card {
        @apply w-auto h-auto border-none;
    }
}
</style>
